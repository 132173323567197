export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'
// Pump Complain List
export const pumpComplainList = '/complain-manage/list'
export const pumpComplainDetails = '/complain-manage/details'
export const pumpComplainResolved = '/complain-manage/resolved'
export const pumpComplainReview = '/complain-manage/review'
export const pumpComplainSend = '/complain-manage/send'
// Pump Complain Maintenance List
export const pumpComplainMaintenanceList = '/required-maintenance/list'
export const pumpComplainMaintenanceResolved = '/required-maintenance/resolved'
export const pumpComplainMaintenanceSend = '/required-maintenance/send'
export const pumpList = '/required-maintenance/pump-list'
export const pumpComplTroEquip = '/required-maintenance/trouble-equipment-store'
export const pumpComplTroEquipDetail = '/required-maintenance/trouble-equipment-details'
export const pumpComplResunk = '/required-maintenance/resunk'
// Pump Complain Maintenance Task List
export const maintenanceTaskList = '/maintenance-task/list'
export const maintenanceTaskResolved = '/maintenance-task/resolved'
export const maintenanceProgressReportStore = '/maintenance-task/progress-report-store'
export const maintenanceProgressReport = '/maintenance-task/get-progress-report'
// Pump Complain Maintenance Task Requisition
export const complainRequisitionId = '/complain-requisition/requisition-id'
export const complainRequisitionStore = '/complain-requisition/store'
export const complainRequisitionDetails = '/complain-requisition/details'
export const complainRequisitionQuantityUpdate = '/complain-requisition/quantity/update'
export const complainRequisitionForwardAllocate = '/complain-requisition/forward-allocate'
export const complainRequisitionReceiveList = '/complain-requisition/receive-requisition-list'
export const complainRequisitionReceive = '/complain-requisition/receive-requisition'
export const complainRequisitionApprove = '/complain-requisition/approve'
// Pump Complain Maintenance supply equipment
export const complainReqSupplyEquipmentList = '/complain-supply-equipment/list'
export const complainSupplyEquipmentStore = '/complain-supply-equipment/store'
export const complainSupplyEquipmentDetails = '/complain-supply-equipment/details'
// Directory
export const directoryListApi = 'pump-directories/list'
export const directoryStoreApi = 'pump-directories/store'
export const directoryUpdateApi = 'pump-directories/update'
export const directoryToggleStatusApi = 'pump-directories/toggle-status'
// report
export const complainReport = 'pump-maintenance/report/complain'
export const farmerList = 'pump-maintenance/report/farmer-list'
export const maintenanceReport = 'pump-maintenance/report/maintenance'
export const ratingFeedbackReport = 'pump-maintenance/report/rating-feedback'
export const userList = ''
export const documentCategoryupdate = ''
export const documentCategorystore = ''
