<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-button @click="pdfExportDetails(item)" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.print') }}
        </b-button>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="2" sm="12">
                      <p class="text-dark">{{ $t('externalUserIrrigation.subject') }} :</p>
                    </b-col>
                    <b-col lg="10" sm="12">
                        <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? complain.subject_bn : complain.subject }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="2" sm="12">
                      <p class="text-dark">{{ $t('externalUserIrrigation.complain_id') }} :</p>
                    </b-col>
                    <b-col lg="10" sm="12">
                        <p class="text-dark">{{ complain.complain_id }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('globalTrans.organization') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ getOrganization(complain.org_id) }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ appHierarchyNames.divisionName }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ appHierarchyNames.upazilaName }}</p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.email') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ complain.email }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ appHierarchyNames.districtName }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="5" sm="12">
                                <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                            </b-col>
                            <b-col lg="7" sm="12">
                                <p class="text-dark">{{ appHierarchyNames.unionName }}</p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12" class="mb-2">
                      <h5>{{ $t('externalUserIrrigation.details') }} :</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-dark" v-html="(this.$i18n.locale === 'bn') ? complain.details_bn : complain.details"></p>
                    </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpComplainDetails } from '../../api/routes'
import HierarchycalDropdownNames from '@/Utils/common'
import ExportPdfDetail from './export_pdf_complain_details'

export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getComplainDetails()
      Object.freeze(tmp)
      this.complain = tmp
    }
  },
  data () {
    return {
        loader: true,
        appHierarchyNames: null,
        complain: '',
        base_url: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    complainData () {
      return Object.assign({}, this.complain, this.appHierarchyNames)
    }
  },
  methods: {
    async getComplainDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${pumpComplainDetails}/${this.$props.id}`)
      if (result.success) {
        this.complain = this.getRelationalData(result.data)
        this.appHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.complain.far_union_id, 'union')
      }
      this.loader = false
    },
    getRelationalData (data) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const orgObject = orgList.find(orgItem => orgItem.value === data.org_id)
        const orgData = {
          org_name: orgObject.text_en, org_name_bn: orgObject.text_bn
        }
        const endLengthEn = data.details.search('</p>')
        const detailEn = data.details.substr(3, endLengthEn - 3)
        const endLengthBn = data.details_bn.search('</p>')
        const detailBn = data.details_bn.substr(3, endLengthBn - 3)
        const DetailsData = {
          detail_bn: detailBn,
          detail_en: detailEn
        }
        return Object.assign({}, data, DetailsData, orgData)
    },
    getOrganization (orgId) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const org = orgList.find(orgItem => orgItem.value === orgId)
        if (this.$i18n.locale === 'bn') {
            return org.text_bn
        } else {
            return org.text_en
        }
    },
    pdfExportDetails () {
      const reportTitle = this.$t('irri_pump_main.complain_report') + ' ' + this.$t('globalTrans.details')
      ExportPdfDetail.exportPdfDetails(reportTitle, this,
        this.complainData
      )
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
